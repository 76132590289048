import { DateTime } from 'luxon';
import { msalInstance } from '..';
import IAppointment from '../models/IAppointment';
import IOrganisationData from '../models/IOrganisationData';

type serviceAppointment = {
  title: string,
  start: string,
  ende: string,
  id: string,
  status: string,
  verein?: string,
  isSeries: boolean,
  canEdit: boolean,
  room: string,
  note: string
}

// const baseUrl: string = 'http://localhost:7071';
const baseUrl: string = 'https://dghapifunctions.azurewebsites.net';
const functionCode: string = `mYvTNFBvZ6a1yC156lP8rB/DlXAhQztL0s7kf/b/CX60TsVhRfJ8uQ==`;

export async function bearerToken() {
  const response = await msalInstance.acquireTokenSilent({
    scopes: []
  });
  return response.accessToken;
}

/**
 * Wandelt die Daten vom Service in die Zielstruktur um
 * @param serviceData Die Termindaten vom Service
 * @returns Liste der geparsten Daten
 */
const parseAppointments = (serviceData: serviceAppointment[]): IAppointment[] => {
  return serviceData.map<IAppointment>(data => {
    return {
      Title: data.title,
      Start: DateTime.fromISO(data.start),
      Ende: DateTime.fromISO(data.ende),
      Id: Number(data.id),
      Status: data.status,
      Verein: data.verein,
      IsSeries: data.isSeries,
      CanEdit: data.canEdit,
      Room: data.room,
      Note: data.note
    }
  });
}

/**
 * Liefert die Liste aller Termine
 * @returns Liste aller Termine
 */
export const getAllAppointments = async (month: DateTime): Promise<IAppointment[]> => {

  const accessToken = await bearerToken();

  if (accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);
    headers.append('Content-Type', 'application/json');

    const response: Response | void = await fetch(`${baseUrl}/api/GetAppointments?code=${functionCode}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        start: month.startOf('month'),
        end: month.endOf('month')
      })
    }).catch(error => console.error(error));

    const result = parseAppointments(await response?.json());
    return result.sort((a, b) => a.Start.toMillis() - b.Start.toMillis());
  } else {
    return [];
  }
}

/**
 * Legt einen neuen Termin an
 * @param appointment Der neu zu erstellende Termin
 * @returns Der neu erstellte Termin
 */
export const addAppointment = async (appointment: IAppointment):
  Promise<{ addedAppointment: boolean, appointment?: IAppointment, message: string }> => {

  const accessToken = await bearerToken();

  if (accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);
    headers.append('Content-Type', 'application/json');

    const response: Response | void = await fetch(`${baseUrl}/api/AddAppointment?code=${functionCode}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        appointment
      }),
    }).catch(error => console.error(error));

    if (response?.status === 409) {
      return {
        addedAppointment: false,
        message: await response.text()
      }

    } else {
      const serviceData: serviceAppointment = await response?.json();
      return {
        addedAppointment: true,
        message: '',
        appointment: {
          Title: serviceData.title,
          Start: DateTime.fromISO(serviceData.start),
          Ende: DateTime.fromISO(serviceData.ende),
          Id: Number(serviceData.id),
          Status: 'Anfrage',
          Verein: serviceData.verein,
          IsSeries: serviceData.isSeries,
          CanEdit: serviceData.canEdit,
          Room: serviceData.room,
          Note: serviceData.note
        }
      };
    }
  } else {
    return {
      addedAppointment: false,
      message: 'No access token found'
    };
  }
}

export const deleteAppointment = async (appointment: IAppointment): Promise<void> => {
  await fetch(`${baseUrl}/api/DeleteAppointment?code=${functionCode}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      appointment
    })
  }).catch(error => console.error(error));
}

export const getOrganizationData = async (): Promise<IOrganisationData | undefined> => {
  const accessToken = await bearerToken();

  if (accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);
    headers.append('Content-Type', 'application/json');

    const response: Response | void = await fetch(`${baseUrl}/api/GetOrganizations?code=${functionCode}`, {
      method: 'GET',
      headers: headers
    }).catch(error => console.error(error));

    const responseData = await response?.json();

    const result: IOrganisationData = {
      IsAdmin: responseData.isAdmin,
      MyVereine: responseData.myVereine,
      Vereine: responseData.vereine
    }
    return result;
  } else {
    return undefined;
  }
}
