import React from 'react';
import styles from '../styles/App.module.scss';

export const Home: React.FC = () => {
  return <>
    <h2>Dorfgemeinschaftshaus<br />Wessum</h2>
    <img className={styles.banner} src='/dgh_ansicht.jpg' alt='Aussenansicht DGH Wessum'></img>


    <div className={styles.textPage}>
      <h3>Nutzungsanfragen</h3>
      <p>
        <span>Anfragen zu Nutzung des Dorfgemeinschaftshaus können über </span>
        <a href="https://forms.office.com/r/djra2QefH5" target="_blank" rel="noreferrer">dieses Formular</a>
        <span> gestellt werden</span>
      </p>
      <p>
        <span>Für die Wessumer Vereine wurde ein Zugang bereitgestellt über den Termine direkt im Kalender eingetragen werden können. Diese Zugänge sollten dem jeweiligen Vorstand vorliegen. Rückfragen hierzu bitte an </span>
        <a href="mailto:zugang@dgh-wessum.de">zugang@dgh-wessum.de</a>.
      </p>
      <h3>Allgemeine Informationen</h3>
      <p>Das Dorfgemeinschaftshaus an der Schulstraße hat die Stadt Ahaus als Bauherr am 06.03.2020 offiziell der Wessumer Bevölkerung übergeben und die Schlüssel der Kolpingsfamilie Wessum überreicht, die das Haus verwaltet. Auf fast eine Million Euro belaufen sich Kosten für das vom Architekturbüro Tenhündfeld geplante Gebäude. Finanziert wurde es rund zur Hälfte durch Förderungen des Bundes, des Landes und auch durch Mittel der Europäischen Union.</p>
      <p>Nach etwas mehr als einjähriger Bauzeit hatten sich auf Einladung der Stadt die Handwerker und Vorsitzenden sowie Verantwortlichen der Wessumer Vereine und Einrichtungen zu einer Feierstunde eingefunden. Bürgermeisterin Karola Voß stellte in ihren Grußworten heraus, dass „ein lang gehegter Wunsch“ endlich in Erfüllung gegangen ist. Der Vorsitzende der Kolpingsfamilie, Bruno Eing, freute sich, dass das Haus „mit Hilfe und Unterstützung vieler“ nunmehr seiner Bestimmung übergeben werden konnte. Ortsvorsteherin Beatrix Wantia stellte als Ziel heraus, dass in diesem Haus „die Vereine künftig noch stärker miteinander zusammenarbeiten“.</p>
      <p>Das Dorfgemeinschaftshaus verfügt über einen rund 200 Quadratmeter großen Mehrzweckraum, eine Küche, einen Versammlungsraum im Keller, der auch von der Schießsportabteilung des Bürgerschützenvereins genutzt wird, und ein Foyer mit Toiletten. In der angrenzenden Lagerhalle können Wessumer Vereine Gegenstände für die Ferienspiele und das Zeltlager unterbringen. Auf fast eine Million Euro belaufen sich Kosten für das vom Architekturbüro Tenhündfeld geplante Gebäude. Finanziert wurde es rund zur Hälfte durch Förderungen des Bundes, des Landes und auch durch Mittel der Europäischen Union.</p>
      <p>So steht dieses Haus insbesondere für Veranstaltungen der Wessumer Vereine zur Verfügung. Regelmäßig finden dort Vorstandssitzungen, Chorproben, aber auch kulturelle Veranstaltungen statt.</p>
    </div>
  </>
}
