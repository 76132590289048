import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { initializeIcons } from '@fluentui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { msalConfig } from './services/AuthConfig';


export const msalInstance = new PublicClientApplication(msalConfig);
initializeIcons();

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event && event.payload && event.payload['account']) {
    const account = event.payload['account']
    msalInstance.setActiveAccount(account)
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App instance={msalInstance} />
  </React.StrictMode>,
  document.getElementById('root')
);