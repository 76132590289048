import linq from 'linq';
import { DateTime } from 'luxon';
import React from 'react';
import IAppointment from '../../models/IAppointment';
import DayTile from './DayTile/DayTile';

type MonthViewProps = {
    appointments: IAppointment[],
    openAppointment: (appointment: IAppointment) => void
    currentMonth: DateTime
}

const MonthView: React.FC<MonthViewProps> = ({ appointments, openAppointment, currentMonth }): JSX.Element => {

    const dayTiles: JSX.Element[] = [];
    for (let day = 1; day <= currentMonth.daysInMonth; day++) {
        const date: DateTime = DateTime.local(currentMonth.year, currentMonth.month, day);

        const appointmentsThatDay: IAppointment[] = linq.from(appointments)
            .where(a => {
                return (+a.Start.startOf('day') <=  +date.startOf('day')) 
                && (+a.Ende.endOf('day') >= +date.endOf('day'))
            })
            .orderBy(a => a.Start)
            .toArray();
        dayTiles.push(<DayTile day={date} appointments={appointmentsThatDay} openAppointment={openAppointment} key={date.toLocaleString(DateTime.DATE_SHORT)}></DayTile>);
    }

    return <>
        {dayTiles}
    </>;
}

export default MonthView;