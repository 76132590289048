import { IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PageLayout } from './components/PageLayout/PageLayout';
import { Datenschutz } from './pages/Datenschutz';
import { Home } from './pages/Home';
import { Impressum } from './pages/Impressum';
import Overview from './pages/Overview';
import styles from './styles/App.module.scss';

const Pages: React.FC = () => {
  return (
    <Switch>
      <Route path='/impressum'>
        <Impressum></Impressum>
      </Route>
      <Route path='/kalender'>
        <>
          <AuthenticatedTemplate>
            <Overview></Overview>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Home></Home>
          </UnauthenticatedTemplate>
        </>
      </Route>
      <Route path='/datenschutz'>
        <Datenschutz></Datenschutz>
      </Route>
      <Route path='/'>
        <Home></Home>
      </Route>
    </Switch>
  )
}

const App: React.FC<{ instance: IPublicClientApplication }> = ({ instance }): JSX.Element => {
  return (
    <div className={styles.App}>
      <div className={styles.data}>
        <MsalProvider instance={instance}>
          <Router>
            <PageLayout>
              <Pages />
            </PageLayout>
          </Router>
        </MsalProvider >
      </div>
    </div>
  );
}

export default App;