import { Dropdown, IDropdownOption, ResponsiveMode } from '@fluentui/react';
import { DateTime } from 'luxon';
import React from 'react';
import styles from './MonthSelection.module.scss';

type MonthSelectionProps = {
    selectedMonthChanged: (month: DateTime) => void;
}

const MonthSelection: React.FC<MonthSelectionProps> = (props) => {

    const today: DateTime = DateTime.local();

    const [selectedYear, setSelectedYear] = React.useState<number>(today.year);
    const [selectedMonth, setSelectedMonth] = React.useState<number>(today.month);

    const years: IDropdownOption[] = [
        { key: 2021, text: '2021' },
        { key: 2022, text: '2022' },
        { key: 2023, text: '2023' },
        { key: 2024, text: '2024' },
        { key: 2025, text: '2025' },
        { key: 2026, text: '2026' },
        { key: 2027, text: '2027' },
        { key: 2028, text: '2028' },
        { key: 2029, text: '2029' },
        { key: 2030, text: '2030' }
    ];

    const months: IDropdownOption[] = [
        { key: 1, text: 'Januar' },
        { key: 2, text: 'Februar' },
        { key: 3, text: 'März' },
        { key: 4, text: 'April' },
        { key: 5, text: 'Mai' },
        { key: 6, text: 'Juni' },
        { key: 7, text: 'Juli' },
        { key: 8, text: 'August' },
        { key: 9, text: 'September' },
        { key: 10, text: 'Oktober' },
        { key: 11, text: 'November' },
        { key: 12, text: 'Dezember' },
    ];

    const onSelectYear = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        const year: number = Number(option?.key);
        if (year) {
            setSelectedYear(year);
            props.selectedMonthChanged(DateTime.local(year, selectedMonth, 1));
        }
    }

    const onSelectMonth = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        const month: number = Number(option?.key);
        if (month) {
            setSelectedMonth(month);
            props.selectedMonthChanged(DateTime.local(selectedYear, month, 1));
        }
    }


    return <div className={styles.monthSelection}>
        <Dropdown
            className={styles.dropDownMonth}
            responsiveMode={ResponsiveMode.large}
            selectedKey={selectedMonth}
            options={months}
            onChange={onSelectMonth}></Dropdown>
        <Dropdown
            className={styles.dropDownYear}
            responsiveMode={ResponsiveMode.large}
            selectedKey={selectedYear}
            options={years}
            onChange={onSelectYear}
        ></Dropdown>
    </div>
}

export default MonthSelection;