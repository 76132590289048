import { DialogContent } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContentText, DialogTitle, IconButton, MenuItem, Select, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import IAppointment from '../../models/IAppointment';
import IOrganisationData from '../../models/IOrganisationData';
import { IVerein } from '../../models/IVerein';
import { addAppointment as setAppointment, deleteAppointment } from '../../services/AppointmentService';
import styles from './AppointmentForm.module.scss';

type AppointmentFormProps = {
  appointment?: IAppointment,
  isNew: boolean,
  isOpen: boolean,
  dismissPanel: () => void,
  organisationData: IOrganisationData | undefined,
  reloadData: () => void
}

const minutes: number[] = [0, 15, 30, 45];

const AppointmentForm: React.FC<AppointmentFormProps> = (props) => {
  const { isNew, isOpen, dismissPanel, appointment, reloadData, organisationData } = props;

  const [title, setTitle] = useState<string>('')
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [isValid, setIsValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [note, setNote] = useState('');
  const [room, setRoom] = useState('');
  const [verein, setVerein] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDeleteWarningDialog, { setTrue: openDeleteConfirmation, setFalse: closeDeleteConfirmation }] = useBoolean(false);
  const [isSuccessMessageOpen, { setTrue: showSuccess, setFalse: hideSuccess }] = useBoolean(false);
  const [isErrorMessageOpen, { setTrue: showError, setFalse: hideError }] = useBoolean(false);

  useEffect(() => {

    let dateTimeNow = DateTime.local().plus({ hours: 1 });

    const roundedMinute: number = Number(minutes.reduce((prevValue, currentValue) => { return dateTimeNow.minute >= currentValue ? currentValue : prevValue }));

    dateTimeNow = dateTimeNow.set({ minute: roundedMinute })

    setTitle((!isNew && appointment) ? appointment.Title : '');
    setStart((!isNew && appointment) ? appointment.Start.toJSDate() : dateTimeNow.toJSDate());
    setEnd((!isNew && appointment) ? appointment.Ende.toJSDate() : dateTimeNow.plus({ hours: 1 }).toJSDate());
    setNote((!isNew && appointment) ? appointment.Note : '');
    setRoom((!isNew && appointment) ? appointment.Room : 'Saal');
    setVerein((!isNew && appointment && appointment.Verein) ? appointment.Verein : `${((organisationData && organisationData.MyVereine.length > 0) ? organisationData?.MyVereine[0].title : '')}`);
    hideSuccess();
    hideError();
  }, [isNew, appointment, isOpen, hideSuccess, hideError, organisationData, organisationData?.MyVereine]);


  const validateForm = () => {
    let valid = false;
    if (title && title.length > 0 && start && end) {
      const dateNow: DateTime = DateTime.local();
      var { dateStart, dateEnd }: { dateStart: DateTime; dateEnd: DateTime; } = parseStartAndEnd(start, end);
      if (dateStart > dateNow && dateEnd > dateStart) {
        valid = true;
      }
    }
    setIsValid(valid);
  }
  useEffect(validateForm, [title, start, end, isOpen]);

  const saveAppointment = React.useCallback(async () => {
    setIsSaving(true);
    var { dateStart, dateEnd }: { dateStart: DateTime; dateEnd: DateTime; } = parseStartAndEnd(start, end);
    const appntm = {
      Title: title,
      Start: dateStart,
      Ende: dateEnd,
      Id: !isNew && appointment ? appointment.Id : 0,
      Status: appointment?.Status || 'Anfrage',
      IsSeries: false,
      CanEdit: true,
      Verein: verein,
      Room: room,
      Note: note
    }
    const result = await setAppointment(appntm);
    console.log(result);
    if (result.addedAppointment) {
      showSuccess();
      hideError();
    } else {
      setErrorMessage('Fehler beim Speichern!');
      hideSuccess();
      showError();
    }
    reloadData();
    setIsSaving(false);
  }, [start, end, title, isNew, appointment, room, verein, note, reloadData, showError, showSuccess, hideError, hideSuccess]);

  let content: JSX.Element;

  if (!isNew && appointment && (!appointment.CanEdit || appointment.IsSeries)) {
    content = <div><h1>{title}</h1>
      <div>{'Start'}</div>
      <div>{DateTime.fromJSDate(start).setLocale('de-DE').toLocaleString(DateTime.DATETIME_FULL)}</div>
      <div>{'Ende'}</div>
      <div>{DateTime.fromJSDate(end).setLocale('de-DE').toLocaleString(DateTime.DATETIME_FULL)}</div>
      <div>{'Raum'}</div>
      <div>{appointment.Room}</div>
      <div>{'Verein'}</div>
      <div>{appointment.Verein}</div>
      <div>{'Bemerkung'}</div>
      <div>{appointment.Note}</div>
      <div>{'Verein'}</div>
      <div>{appointment.Verein}</div>
    </div>
  }
  else {
    console.log(organisationData);
    content = <div><h1>{isNew ? 'Hinzufügen' : 'Bearbeiten'}</h1>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <form>
          <div>{'Titel'}</div>
          <TextField
            fullWidth
            required
            defaultValue={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }
            }>
          </TextField>
          <div>{'Start'}</div>
          <DateTimePicker
            renderInput={(props) => <TextField fullWidth {...props}></TextField>}
            minDate={DateTime.local()}
            value={start}
            ampm={false}
            ampmInClock={false}
            minutesStep={15}
            cancelText={'Schließen'}
            toolbarTitle={'Datum und Uhrzeit wählen'}
            onChange={(newValue) => {
              if (newValue instanceof DateTime) {
                setStart(newValue.toJSDate())
              }
            }}
          ></DateTimePicker>
          <div>{'Ende'}</div>
          <TimePicker
            renderInput={(params) => <TextField fullWidth {...params} />}
            minTime={DateTime.fromJSDate(start)}
            ampm={false}
            ampmInClock={false}
            minutesStep={15}
            value={end}
            onChange={(newValue) => {
              if (newValue instanceof DateTime) {
                setEnd(newValue.toJSDate())
              }
            }}
          ></TimePicker>
          <div>{'Raum'}</div>
          <Select
            value={room}
            onChange={(event) => setRoom(event.target.value)}
            fullWidth
          >
            <MenuItem value={'Saal'}>Saal</MenuItem>
            <MenuItem value={'Küche'}>Küche</MenuItem>
            <MenuItem value={'Keller'}>Keller</MenuItem>
          </Select>
          {
            organisationData && (organisationData.MyVereine?.length > 1 || organisationData.IsAdmin) &&
            <>
              <div>{'Verein'}</div>
              <Box>
                <Select value={verein} onChange={(event) => setVerein(event.target.value)} fullWidth={true}>
                  {(organisationData?.IsAdmin ? organisationData?.Vereine : organisationData?.MyVereine)
                    .sort((a: IVerein, b: IVerein) => a.title.localeCompare(b.title))
                    .map(v => <MenuItem key={v.id} value={v.title}>{v.title}</MenuItem>)}
                </Select>
              </Box>
            </>
          }
          <div>{'Bemerkung'}</div>
          <TextField
            fullWidth
            multiline
            rows={4}
            defaultValue={note}
            onChange={(event) => {
              setNote(event.target.value);
            }
            }>
          </TextField>
        </form>
      </LocalizationProvider>
    </div >
  }

  const getAlerts = (): JSX.Element[] => {
    const alerts: JSX.Element[] = [];
    if (isSuccessMessageOpen) {
      alerts.push(<Alert
        action={
          <IconButton aria-label='close' color='inherit' size='small' onClick={hideSuccess}>
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }
        variant='outlined'
        severity='success'
        sx={{ mb: 2 }}
      >
        Speichern erfolgreich!
      </Alert>)
    }
    if (isErrorMessageOpen) {
      alerts.push(<Alert
        action={
          <IconButton aria-label='close' color='inherit' size='small' onClick={hideError}>
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }
        variant='outlined'
        severity='error'
        sx={{ mb: 2 }}
      >
        {errorMessage}
      </Alert>)
    }
    return alerts;
  }

  const deleteConfirmation = <Dialog
    open={isDeleteWarningDialog}
    onClose={closeDeleteConfirmation}
  >
    <DialogTitle>{'Termin löschen?'}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Soll der Termin gelöscht werden?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDeleteConfirmation}>Nein</Button>
      <Button onClick={() => {
        if (appointment) {
          setIsSaving(true);
          closeDeleteConfirmation();
          deleteAppointment(appointment).then(() => {
            reloadData();
            dismissPanel();
            setIsSaving(false);
          });
        }
      }}>Ja</Button>
    </DialogActions>
  </Dialog>;

  const canDelete = (appointment: IAppointment | undefined): boolean => (appointment && !isNew && appointment.CanEdit) ? true : false;
  const canSave = (appointment: IAppointment | undefined): boolean => (isNew || !appointment || (appointment.CanEdit && !appointment.IsSeries));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return <>
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={() => dismissPanel()}
    >
      <div className={styles.addAppointmentContainer}>
        {content}
        <Box sx={{ marginTop: '10px' }}>
          {getAlerts()}
        </Box>
        {isSaving ?
          <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', marginTop: '10px' }}>
            <CircularProgress />
          </Box> :
          <Stack justifyContent={'flex-end'} spacing={2} direction='row' className={styles.bottomRow}>
            {canDelete(appointment) && <Button onClick={openDeleteConfirmation} variant='outlined'>Löschen</Button>}
            {canSave(appointment) && <Button disabled={!isValid} onClick={saveAppointment} variant='contained'>Speichern</Button>}
            <Button onClick={dismissPanel} variant='outlined'>Schließen</Button>
          </Stack>
        }
      </div>
      {deleteConfirmation}
    </Dialog>
  </>
}

export default AppointmentForm;

function parseStartAndEnd(start: Date, end: Date) {
  const dateStart: DateTime = DateTime.fromJSDate(start);
  let dateEnd: DateTime = DateTime.fromJSDate(start);
  dateEnd = dateEnd.set({
    hour: end.getHours(),
    minute: end.getMinutes()
  });
  return { dateStart, dateEnd };
}
