import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { ActionButton } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/App.module.scss';

export const NavigationBar: React.FC = () => {

  const { instance } = useMsal();

  return <nav>

    <Link className={styles.homeLink} to='/'>
      <img src='/favicon.ico' alt='DGH Logo'></img>
      <ActionButton
        text='DGH' />
    </Link>
    <UnauthenticatedTemplate>
      <ActionButton
        iconProps={{ iconName: 'Signin' }}
        onClick={() => instance.loginRedirect()}
        text='Anmelden' />
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <Link to='/kalender'>
        <ActionButton
          iconProps={{ iconName: 'Calendar' }}
          text='Kalender' />
      </Link>
      <ActionButton
        iconProps={{ iconName: 'Signout' }}
        onClick={() => instance.logoutRedirect()}
        text='Abmelden' />
    </AuthenticatedTemplate>
  </nav>
}