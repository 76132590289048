import { PrimaryButton, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React from 'react';
import styles from './AddAppointmentButton.module.scss';

type AddAppointmentButtonProps = {
  onClick: () => void
}

const AddAppointmentButton: React.FC<AddAppointmentButtonProps> = ({ onClick }): JSX.Element => {

  const tooltipId = useId('toolTip');

  return <>
    <TooltipHost
      content='Neuen Termin eintragen'
      id={tooltipId}
      calloutProps={{ gapSpace: 0 }}
      styles={{ root: { display: 'inline-block' } }}    >
      <PrimaryButton
      color='#108615'
        className={styles.button}
        iconProps={{ iconName: 'Add' }}
        onClick={onClick} />
    </TooltipHost>
  </>;
}

export default AddAppointmentButton;