import { ActionButton } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { NavigationBar } from '../NavigationBar/NavigationBar';
import { ScrollToTop } from '../ScrollToTop/ScrollToTop';

export const PageLayout: React.FC = (props) => {
  return <>
    <ScrollToTop />
    <NavigationBar />
    {props.children}
    <footer>
      <Link to='/impressum'>
        <ActionButton
          text='Impressum' />
      </Link>
      <Link to='/datenschutz'>
        <ActionButton
          text='Datenschutz' />
      </Link>
    </footer>
  </>;
}