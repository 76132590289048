import { AuthenticationResult, InteractionRequiredAuthError, LogLevel } from "@azure/msal-browser";
import { useMsal, useAccount } from '@azure/msal-react';

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: `${process.env["REACT_APP_AAD_APP_CLIENT_ID"]}`, // This is the ONLY mandatory field that you need to supply.
        authority: `https://login.microsoftonline.com/${process.env["REACT_APP_AAD_APP_TENANT_ID"]}`, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

export const GetToken = async (): Promise<string> => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    if (account && inProgress === "none") {
        try {
            const response: AuthenticationResult = await instance.acquireTokenSilent({
                scopes: protectedResources.functionApi.scopes,
                account: account
            });
            return response.accessToken;
        }
        catch (error) {
            // in case if silent token acquisition fails, fallback to an interactive method
            if (error instanceof InteractionRequiredAuthError) {
                if (account && inProgress === "none") {
                    try {
                        const response: AuthenticationResult = await instance.acquireTokenPopup({
                            scopes: protectedResources.functionApi.scopes,
                        })
                        return response.accessToken;
                    }
                    catch (error) { console.error(error) };
                }
                return '';
            }
        }
    }
    return '';
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: []
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
    graphMe: {
        endpoint: "https://graph.microsoft.com/v1.0/me",
        scopes: ["User.Read"],
    },
    functionApi: {
        endpoint: "/api/getAppointments",
        scopes: [`${process.env["REACT_APP_AAD_APP_FUNCTION_SCOPE_URI"]}/access_as_user`], // e.g. api://xxxxxx/access_as_user
    }
}
