import { useBoolean } from '@fluentui/react-hooks';
import { Alert, CircularProgress } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import AddAppointmentButton from '../components/AddAppointmentButton/AddAppointmentButton';
import AppointmentForm from '../components/AppointmentForm/AppointmentForm';
import MonthSelection from '../components/MonthSelection/MonthSelection';
import MonthView from '../components/MonthView/MonthView';
import IAppointment from '../models/IAppointment';
import IOrganisationData from '../models/IOrganisationData';
import { getAllAppointments, getOrganizationData } from '../services/AppointmentService';
import styles from '../styles/App.module.scss';

const Overview: React.FC = () => {

  const [isNew, setIsNew] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<IAppointment[]>();
  const [currentMonth, setCurrentMonth] = useState<DateTime>(DateTime.local());
  const [isFormOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [selectedAppointment, setSelectedAppointment] = useState<IAppointment>();
  const [organizationData, setOrganizationData] = useState<IOrganisationData>();

  const loadAppointements = React.useCallback(async () => {
    setAppointments(undefined);
    var appointments = await getAllAppointments(currentMonth);
    setAppointments(appointments);
  }, [currentMonth]);

  useEffect(() => {
    if (!appointments) {
      loadAppointements();
    }
  }, [appointments, currentMonth, loadAppointements]);


  useEffect(() => {
    if (!organizationData) {
      getOrganizationData().then(setOrganizationData);
    }
  }, [organizationData]);

  const setMonth = (month: DateTime) => {
    setAppointments(undefined);
    getAllAppointments(month).then(setAppointments);
    setCurrentMonth(month);
  }

  const openAppointment = (appointment: IAppointment) => {
    setSelectedAppointment(appointment);
    setIsNew(false);
    openPanel();
  }

  const addAppointment = () => {
    setSelectedAppointment(undefined);
    setIsNew(true);
    openPanel();
  }

  return <>
    <Alert
      variant='outlined'
      severity='warning'
      sx={{ mb: 2 }}
    ><span>Probleme und Fragen bitte an </span>
      <a href="mailto:zugang@dgh-wessum.de">zugang@dgh-wessum.de</a>
    </Alert>
    <MonthSelection selectedMonthChanged={setMonth}></MonthSelection>
    {
      !appointments
        ? <div className={styles.loadingIndicatorContainer}><CircularProgress color="inherit" /></div>
        : <MonthView openAppointment={openAppointment} currentMonth={currentMonth} appointments={appointments} ></MonthView>
    }
    <AddAppointmentButton onClick={addAppointment}></AddAppointmentButton>
    <AppointmentForm
      isNew={isNew}
      dismissPanel={dismissPanel}
      isOpen={isFormOpen}
      appointment={selectedAppointment}
      organisationData={organizationData}
      reloadData={loadAppointements}
    ></AppointmentForm>
  </>;
}

export default Overview;