import { Box } from '@mui/material';
import { DateTime, Duration } from 'luxon';
import React from 'react';
import IAppointment from '../../models/IAppointment';
import styles from './AppointmentTile.module.scss';

type AppointmentTileProps = {
  appointment: IAppointment,
  openAppointment: (appointment: IAppointment) => void
}

const AppointmentTile: React.FC<AppointmentTileProps> = (props): JSX.Element => {
  const { appointment, openAppointment } = props;

  let statusClass = '';
  switch (appointment.Status) {
    case 'Anfrage':
      statusClass = styles.statePending;
      break;
    case 'Genehmigt':
      statusClass = styles.stateApproved;
      break;
    case 'Abgelehnt':
      statusClass = styles.stateDeclined;
      break;
  }

  const duration: Duration = appointment.Ende.diff(appointment.Start, ['hours', 'minutes']);

  return <div className={styles.tile} onClick={() => openAppointment(appointment)}>
    <div className={styles.time}>
      <div>{appointment.Start.toFormat('HH:mm')}</div>
      <div>{duration.toFormat(duration.hours > 0 ? (duration.minutes === 0 ? `h'h'` : `h'h' mm'm'`) : `mm 'm'`)}</div>
    </div>
    <div className={`${styles.state} ${statusClass}`}></div>
    <div className={styles.data}>
      <div>{appointment.Title}</div>
      <div>{`Start: ${appointment.Start.toLocaleString(DateTime.TIME_24_SIMPLE)}`}</div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{`Ende: ${appointment.Ende.toLocaleString(DateTime.TIME_24_SIMPLE)}`}</div>
        <div>{appointment.Verein}</div>
      </Box>
    </div >
  </div>;
}

export default AppointmentTile;