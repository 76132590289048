import { DateTime } from 'luxon';
import React from 'react';
import IAppointment from '../../../models/IAppointment';
import AppointmentTile from '../../AppointmentTile/AppointmentTile';
import styles from './DayTile.module.scss';

type DayTileProps = {
    day: DateTime,
    appointments: IAppointment[],
    openAppointment: (appointment: IAppointment) => void
}

const DayTile: React.FC<DayTileProps> = ({ day, appointments, openAppointment }) => {

    const events: JSX.Element[] = [];

    if (appointments.length > 0) {
        events.push(...appointments.map(a => <AppointmentTile appointment={a} key={a.Id} openAppointment={openAppointment}></AppointmentTile>));

    } else {
        events.push(<div key={`empty_${day.toLocaleString()}`} className={styles.noEvents}>{'Keine Termine'}</div>);
    }
    return <div className={styles.dayTile}>
        <div className={styles.date}>{day.setLocale('de').toFormat('EEEE, dd. MMMM')}</div>
        {events}
    </div>;
}

export default DayTile;